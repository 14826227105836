import '../scss/styles.scss';
import './contao/custom-elements';
import accordion from './module/accordion'
import './module/navigation'
import carousel from './module/carousel'
import custom from './module/custom'
import './module/search'
import './module/forms'
import './module/datepicker'

// import cookiebanner from './module/cookiebanner'