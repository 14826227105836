window.addEventListener("load", () => {
  const mobileNavigation = document.querySelector(".mobile-navigation");
  const mobileTrigger = document.querySelector(".header-trigger");

  if (mobileNavigation && mobileTrigger) {
    mobileTrigger.addEventListener("click", () => {
      mobileNavigation.classList.toggle("active");
      mobileTrigger.classList.toggle("active");
    });
  }
});
