window.addEventListener("load", () => {

  let searchContainer = document.querySelector(".header-search")
  let searchButton = document.querySelector(".header-search button");

  if (searchContainer && searchButton) {
    searchButton.addEventListener("click", (event) => {
      if (768 > window.innerWidth && !searchContainer.classList.contains("open")) {
        event.preventDefault();
        searchContainer.classList.add("open")
      }
    });
  }
});
