import { Datepicker } from "vanillajs-datepicker";
import localeDE from "vanillajs-datepicker/locales/de";

Object.assign(Datepicker.locales, localeDE);

let inputs = document.querySelectorAll("input.pick-date");
if (inputs) {
  inputs.forEach(function (input) {
    new Datepicker(input, {
      language: "de",
      autohide: true,
      clearButton: true
    });
  }); 
}
