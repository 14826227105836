window.addEventListener("load", () => {

  let toggler = document.querySelector(".toggle-elektroschrott input[type='checkbox']")
  let fields = document.querySelectorAll(".widget.elektroschrott");

  if (toggler && fields) {
    toggler.addEventListener("change", (event) => {
      fields.forEach((field) => {
        field.classList.toggle("hide")
      });
    });
  }
});
